<template>
  <div class="bigBox">
    <div class="home-header-container">
    <div class="left">
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/wemeetLogo.png" alt="" @click="handleJumpIndex">
    </div>
    <div class="right d-flex flex-center" @click="handleJumpPage">{{ type == 'home' ? '立即体验' : '首页' }}</div>
  </div>
  </div>
</template>
<script>
export default {
  name:'homeHeader',
  props:{
    type: {
      type: String,
      default: "home",
    }
  },
  components:{},
  data() {
    return {}
  },
  methods: {
    handleJumpPage() {
      let type = this.type 
      if(type == 'home') {
        this.$router.push("/linkUs")
      } else {
        this.$router.push("/")
      }
    },
    handleJumpIndex() {
      this.$router.push("/")
    }
  },
  mounted() {}
}
</script>
<style scoped lang="less">
.bigBox {
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 999;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: .625em 1.5625em .625em 1.25em;
  box-sizing: border-box;
  .left {
      img {
        display: block;
        width: 8.125em;
        height: 2.25em;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .right {
      padding: 0.425em 0.675em;
      box-sizing: border-box;
      line-height: 1;
      color: #00C4C9;
      font-size: 1.1em;
      border-radius: 0.375em;
      border: 1px solid #00C4C9;
      cursor: pointer;
    }
}
// 移动端开始
@media screen and  (max-width: 768px) { 
  .home-header-container {
    height: 55px;
    padding: 0 25px 0 20px;
    .left {
      img {
        display: block;
        width: 115px;
        height: 32px;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .right {
      padding: 6px 10px;
      box-sizing: border-box;
      line-height: 1;
      color: #00C4C9;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #00C4C9;
      cursor: pointer;
    }
  }
}
// 移动端结束

// 平板开始
@media screen and (min-width: 768px) and (max-width: 992px) {
  .bigBox {
    font-size: 14px;
  }
}
// 平板结束

// 中等屏幕开始

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .bigBox {
    font-size: 18px;
  }
}

// 中等屏幕结束

// 大屏幕1200px - 1596px 开始
@media screen and (min-width: 1200px) and (max-width: 1596px) {
  .bigBox {
    font-size: 20px;
  }
}
// 大屏幕1200px - 1596px 结束


// 大屏幕1596px 开始
@media screen and (min-width: 1596px) and (max-width: 2200px) {
  .bigBox {
    font-size: 20px;
  }
}
// 大屏幕1596px 结束

// 大屏幕2200px 开始
@media screen and (min-width: 2200px)  {
  .bigBox {
    font-size: 24px;
  }
}
// 大屏幕2200px 结束

</style>