class WmtDate {
  fromatDate(fmt, dateTime) {
    let date = dateTime ? dateTime : new Date();
    var o = {
      "Y+": date.getFullYear(), // 年份
      "M+": date.getMonth() + 1, // 月份
      "d+": date.getDate(), // 日
      "h+": date.getHours(), // 小时
      "m+": date.getMinutes(), // 分
      "s+": date.getSeconds(), // 秒
      "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return fmt;
  }

  /**
   * 获取指定时差的时间
   *  */
  getDays(nowDate, reduceDay) {
    let oneDay = 24 * 60 * 60 * 1000;
    let endTime = new Date(nowDate);
    endTime = this.fromatDate("yyyy-MM-dd", endTime);
    let startTime = new Date(nowDate - reduceDay * oneDay);
    startTime = this.fromatDate("yyyy-MM-dd", startTime);
    const days = {
      startTime,
      endTime,
    };
    return days;
  }

  fun_date(nowDate, aa) {
    var date1 = nowDate;
    var time1 = this.fromatDate("yyyy-MM-dd", date1);
    var date2 = new Date(date1);
    date2.setDate(date1.getDate() + aa);
    var time2 = this.fromatDate("yyyy-MM-dd", date2);
    return time2;
  }

  // 获取2个日期中间所有日期
  getdiffdate(stime, etime) {
    //初始化日期列表，数组
    var diffdate = new Array();
    var i = 0;
    //开始日期小于等于结束日期,并循环
    while (stime <= etime) {
      diffdate[i] = stime;
      //获取开始日期时间戳
      var stime_ts = new Date(stime).getTime();
      //增加一天时间戳后的日期
      var next_date = stime_ts + 24 * 60 * 60 * 1000;
      stime = this.fromatDate('yyyy-MM-dd', new Date(next_date))
      //增加数组key
      i++;
    }
    console.log(diffdate);
    return diffdate
  }

  /**
   * 根据时间大小排序
   * @param {Array}  需要排序数组
   */
  MsgSort(arr) {
    arr.sort((a, b) => {
      let t1 = new Date(a.startTime.replace(/-/g, "/"));
      let t2 = new Date(b.startTime.replace(/-/g, "/"));
      return t1.getTime() - t2.getTime();
    });
    return arr;
  }

  /**
   * 按照日期分类
   */
  dataResort(data) {
    // 定义空数组，用于存储新组装的数据
    let newArr = [];
    // 遍历数组
    data.forEach((item, i) => {
      // 默认当前操作的数据下标 -1
      let index = -1;
      // 判断数组中是否已经存在当前遍历数据的时间
      let isExists = newArr.some((newItem, j) => {
        if (item.dateType == newItem.date) {
          // 存在就保存当前数据下标  用于插入数据
          index = j;
          return true;
        }
      });
      // console.log("item=========",item)
      // console.log("isExists=========",isExists)
      // console.log("index=========",index)
      // 如果没有就存储一条新对象数据
      if (!isExists) {
        newArr.push({
          date: item.dateType,
          subList: [item],
        });
      } else {
        // 如果有就插入到已存在的对象中
        newArr[index].subList.push(item);
      }
    });

    // 返回新数组
    return newArr;
  }

  /**
   * 数据补全
   */
  formatVal(val) {
    return val < 10 ? "0" + val : val;
  }

  /**
   * 获取当前日期
   */
  getNowDate() {
    //定义一个日期对象;
    var dateTime = new Date(new Date());
    //获得系统年份;
    var year = dateTime.getFullYear();
    //获得系统月份;
    var month = dateTime.getMonth() + 1;
    //获得系统当月分天数;
    var day = dateTime.getDate();
    //获得系统星期几;
    var dayCycle = dateTime.getDay();
    var dayCycleNum = dateTime.getDay();
    //使用数组更改日期样式;
    var dayCycleArray = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (var i = 0; i < 7; i++) {
      if (dayCycle == i) {
        //将dayCycleArray的数赋值到系统星期几里面中去;
        dayCycle = dayCycleArray[i];
      }
    }
    console.log(month);
    console.log(dayCycle);
    let monthArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthStr = monthArr[month - 1];
    day < 10 ? (day = "0" + day) : day;
    //打印完整的系统日期;
    let dateObj = {
      year,
      month,
      day,
      monthStr,
      dayCycle,
      dayCycleNum,
    };
    return dateObj;
  }

  // 分钟倒计时
  /**
   * @param nowDate 当前时间
   * @param expired_time 有效期时间
   */
  countDownMin(nowDate, expired_time) {
    var curDate = new Date(nowDate);
    console.log(curDate, nowDate);
    let _expired_time = typeof expired_time != "number" ? 48 : expired_time;
    var nextDate = new Date(
      curDate.getTime() + +_expired_time * 60 * 60 * 1000
    ).getTime(); //后一天
    console.log(nextDate);
    let timeStr = this.timeDown(nextDate);
    return timeStr;
  }

  timeDown(endTime) {
    //获取时间差
    let now = new Date().getTime();

    var date3 = endTime - now; //时间差的毫秒数
    //计算出相差天数
    var days = Math.floor(date3 / (24 * 3600 * 1000));

    //计算出小时数
    var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000));
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000));
    //计算相差秒数
    var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    var seconds = Math.round(leave3 / 1000);
    let getHours = this.padNum(+(days * 24 + hours));
    let getMinutes = this.padNum(+minutes);
    let getSeconds = this.padNum(+seconds);
    if (getHours == 0) {
      return `${getMinutes}:${getSeconds}`;
    } else if (getHours == 0 && getMinutes == 0) {
      return `${getSeconds}`;
    } else if (getHours == 0 && getMinutes == 0 && getSeconds == 0) {
      return ``;
    } else {
      return `${getHours}:${getMinutes}:${getSeconds}`;
    }
  }
  padNum(num) {
    return num < 10 ? "0" + num : num;
  }

  // 判断时间大小
  compareDate(date1, date2) {
    var startDate = new Date(date1);
    var endDate = new Date(date2);
    if (startDate.getTime() > endDate.getTime()) {
      return true; //第一个大
    } else {
      return false; //第二个大
    }
  }

  /**
   * 日期判断
   * @description 相同日期 展示 年月日 开始时间-结束时间，不同日期，年月日开始时间-年月日结束时间
   * @param date1 开始日期
   * @param date2 结束日期
   * @returns dateFormat
   * */
  formatOnceDay(date1, date2) {
    let date = new Date(date1).toString().split(" ");
    let endDate = new Date(date2).toString().split(" ");
    var year = date[3];
    var endYear = endDate[3];
    var month = new Date(date1).getMonth() + 1;
    var day = date[2].indexOf("0") == 0 ? date[2].split("0")[1] : date[2];
    var Hour =
      new Date(date1).getHours().toString() < 10
        ? "0" + new Date(date1).getHours().toString()
        : new Date(date1).getHours().toString();
    var Minutes =
      new Date(date1).getMinutes().toString() < 10
        ? "0" + new Date(date1).getMinutes().toString()
        : new Date(date1).getMinutes().toString();
    var endMonth = new Date(date2).getMonth() + 1;
    var endDay =
      endDate[2].indexOf("0") == 0 ? endDate[2].split("0")[1] : endDate[2];
    var endHour =
      new Date(date2).getHours().toString() < 10
        ? "0" + new Date(date2).getHours().toString()
        : new Date(date2).getHours().toString();
    var endMinutes =
      new Date(date2).getMinutes().toString() < 10
        ? "0" + new Date(date2).getMinutes().toString()
        : new Date(date2).getMinutes().toString();

    // 开始日期和结束日期同一天时
    if (
      date[1] == endDate[1] &&
      date[2] == endDate[2] &&
      date[3] == endDate[3]
    ) {
      return (
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        Hour +
        ":" +
        Minutes +
        "-" +
        endHour +
        ":" +
        endMinutes
      );
    } else {
      return (
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        Hour +
        ":" +
        Minutes +
        " - " +
        endYear +
        "/" +
        endMonth +
        "/" +
        endDay +
        " " +
        endHour +
        ":" +
        endMinutes
      );
    }
  }

  // ${+seconds}秒
}

let wmtDate = new WmtDate();

export default wmtDate;

