
<template>
  <div class="home page">
    <!-- 首页头部 -->
    <homeHeader :type="'home'"></homeHeader>
    <!-- 首页轮播图 -->
    <homeCarousel @sendActiveIndex="getActiveIndex"></homeCarousel>
    <!-- 新闻资讯 -->
    <newsInfo :list="newsList"></newsInfo>
    <!-- 首页底部-->
    <homeFooter></homeFooter>
    <!-- 联系我们二维码 -->
    <FollowPop :isShow="isShow"></FollowPop>
     <!-- 关注我们二维码 -->
    <linkUsPop :isShow="isShow1"></linkUsPop>
    <sideNav :activeIndex="activeIndex" @handleIsFollow="handleIsFollow" @handleIsFollow1="handleIsFollow1"></sideNav>
  </div>
</template>
<!-- 
  /* 适用于320px-480px范围内的屏幕 */
@media screen and (min-width: 320px) and (max-width: 480px) {
/* 这里是你的CSS样式 */
}

/* 适用于768px-1024px范围内的屏幕 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
/* 这里是你的CSS样式 */
}

/* 适用于大于1024px的屏幕 */
@media screen and (min-width: 1024px) {
/* 这里是你的CSS样式 */
}
 -->
<script>
// import WmtHeader from '@/components/layout/wmtHeader.vue'
// import WmtFooter from '@/components/layout/wmtFooter.vue'
import homeHeader from '../../components/homeHeader.vue'
import FollowPop from '../../components/FollowPop.vue'
import linkUsPop from '../../components/linkUsPop.vue'
import sideNav from '../../components/sideNav.vue'
import homeCarousel from './components/homeCarousel.vue'
import newsInfo from './components/newsInfo.vue'
import homeFooter from '../../components/homeFooter.vue'
import { getNewsList } from '@/api/public.js'
export default {
  name: 'Home',
  components: {
    homeHeader,
    homeCarousel,
    newsInfo,
    homeFooter,
    FollowPop,
    linkUsPop,
    sideNav
  },
  data() {
    return {
      activeKey: 'home',
      isShow: false,
      isShow1: false,
      activeIndex: 1,
      params:{
        pageNum: 1,
	      pageSize: 10,
        type: 'OFFICIAL_WEBSITE'
      },
      newsList:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let params = {...this.params}
      getNewsList(params).then(res => {
        console.log("1111",res)
        let arr = res?.data?.data || []
        this.newsList = arr.map(item => {
          return {
            ...item,
            date:this.$wmtDate.fromatDate('yyyy-MM-dd', new Date(item.createTime)),
          }
        } )
      })
    },
    handleIsFollow(bool) {
      this.isShow1 = false
      this.isShow = bool
    },
    handleIsFollow1(bool) {
      this.isShow = false
      this.isShow1= bool
    },
    // handleIsFollow1(bool) {
    //   this.isShow1 = bool
    // },
    getActiveIndex(e) {
      // console.log("e",e)
      this.activeIndex = e
    },
   
  }
}
</script>

<style  scoped>
.page {
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
}
.home {
  min-width: 320px;
  margin: 0 auto;
}

</style>