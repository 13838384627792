<template>
  <div class="box follow" :class="isShow ? 'show' : 'hide'" >
    <div class="scan-code-container item1" 	 
		>
      <div class="scan-code">
         <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-mini/scancode-02.png" alt="">
         <div class="txt">添加企微 即时沟通</div>
      </div>
    </div>
    <div class="scan-code-container mt-10 item2" 	 
		>
      <div class="scan-code">
         <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-mini/scancode-01.png" alt="">
         <div class="txt">添加企微 即时沟通</div>
      </div>
    </div>
  </div>
</template>

<script>
	
	export default {
    name: 'linkUsPop',
		props:{
			isShow:{
				type:[Boolean],
				default: false
			},
		},
    data() {
      return {
      }
    },
    methods: {}
	}
</script>

<style scoped lang="less">
  .box {
    position: fixed;
    z-index: 1200;
    right: 50px;
    bottom: 125px;
  }
  .item2 {
    margin-top: 10px;
  }
  .scan-code-container {
    
    .scan-code {
      width: 180px;
      height: 206px;
      background: #FFFFFF;
      border-radius:8px;
      box-sizing: border-box;
      padding: 6px 5px 10px;
      box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.12);
      img {
        display: block;
        width: 100%;
        height: 170px;
      }
      .txt {
        font-size: 14px;
        color: #9E9E9E;
        line-height: 20px;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 768px)  {
    .scan-code-container {
    .scan-code {
      width: 160px;
      height: 186px;
      padding: 5px 4px 6px;
      img {
        height: 150px;
      }
      .txt {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (min-width: 1920px)  {
  .scan-code-container {
    .scan-code {
      width: 220px;
      height: 250px;
      padding: 5px 5px 5px;
      img {
        height: 207px;
      }
      .txt {
        font-size: 20px;
      }
    }
  }

}
	.follow.show{
		display: block;
		transform-origin: right bottom;
		animation: openAnimation 0.2s linear both;
	}
	.follow.hide{
		display: none;
	}
	.follow.none{
		display: none;
	}
	@keyframes openAnimation{
		0%{ opacity: 0; transform: scale(0);}
		100%{ opacity: 1;transform: scale(1) }
	}
</style>