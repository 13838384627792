// 公用配置，需要引入
export const scrollMixins = {
	data() {
		return {}
	},
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  beforeDestroy() {
    // 卸载页面滚动事件
    window.removeEventListener("scroll", this.scrolling);
  }
}