import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import './assets/iconfont/iconfont.css' 
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

Vue.use(VueAwesomeSwiper);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
// 假数据引入
import getData from '@/utils/getData.js';//引入
Vue.use(getData)

import wmtDate from "@/utils/date.js";
Vue.prototype.$wmtDate = wmtDate;

import { Form, Field, Checkbox, Button, RadioGroup, Radio, CheckboxGroup,Popup, Cascader, Grid, GridItem, Tab, Tabs, List, Dialog,Toast, Picker,Icon,Cell, CellGroup, NavBar,DatetimePicker,Divider, Uploader, Image as VanImage, ImagePreview,Row,Col,Search,Overlay,Swipe,SwipeItem,PullRefresh,Lazyload,Area } from 'vant';
// 引入vant组件
Vue.use(Form).use(Field).use(Checkbox).use(Button).use(Radio).use(RadioGroup).use(Checkbox).use(CheckboxGroup).use(Popup).use(Cascader).use(Grid).use(GridItem).use(Tab).use(Tabs).use(List).use(Dialog).use(Toast).use(Picker).use(Icon).use(Cell).use(CellGroup).use(NavBar).use(DatetimePicker).use(Divider).use(Row).use(Col).use(Uploader).use(VanImage).use(ImagePreview).use(Search).use(Overlay).use(Swipe).use(SwipeItem).use(PullRefresh).use(Lazyload).use(Area)

import 'vant/lib/index.css';
// 引入vant组件


Vue.prototype.$bus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
