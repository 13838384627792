//对axios二次封装：使用请求与响应拦截器
import axios from "axios";
console.log(process.env.VUE_APP_API,1211)
//S1、利用axios对象的create方法，去创建axios实例（其他的配置：基础路径，超时时间）
let request = axios.create({
    // baseURL: 'http://localhost:3000/',                 //基础路径:路径上会携带/api
    baseURL: process.env.VUE_APP_API,                 //基础路径:路径上会携带/api
    timeout: 5000                                               //超时时间设置
});


let obj1 = { "language": "CN",  "eventKey": "WeMeet","appKey":"WX_MINI_WEMEET" }

let obj =  JSON.stringify({ ...obj1 })
//S2、request实例添加请求与响应拦截器
request.interceptors.request.use((config)=>{

  config.headers['x-call-client-info'] = obj; // 添加 Authorization 请求头

    return config
},(error)=>{
    return Promise.reject(error)
})
//S3、响应拦截器
request.interceptors.response.use((response) => {
    console.log("响应拦截器",response)
    return response.data                                        //成功回调,简化数据
}, (error) => {                                                 //失败回调处理http网络错误
    return Promise.reject(error);
});

//对外暴露
export default request;//对axios二次封装：使用请求与响应拦截器

