// 前端模拟后端实现分页功能函数
const getList = (obj = {
  pageNum: 1,
  pageSize: 10,
  keywords: ''
}, arr) => {
  let {
    pageNum,
    pageSize,
    keywords
  } = obj
  let list = []
  if (arr.length) {
    let searchList = arr.filter(v => {
      if (keywords && !v.name.includes(keywords)) return false;
      return true
    })
    list = searchList.filter((item, index) => index < pageSize * pageNum && index >= pageSize * (pageNum - 1))
  }
  var obj = {
    code: '200',
    list,
    total: arr.length,
    pageNum: pageNum,
    pages: Math.ceil(arr.length / pageSize)
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(obj)
    }, 1200)
  })
}
export default {
  install(Vue) {
    Vue.prototype.$getList = getList
  }
}